import { lazyWithReload as lazy } from '@segunosoftware/equinox';

export const OAuth = lazy(() => import('../components/OAuth'));

export const CreateDiscountSet = lazy(() => import('../components/CreateDiscountSet'));
export const DiscountSetList = lazy(() => import('../components/DiscountSetList'));
export const EditDiscountSet = lazy(() => import('../components/EditDiscountSet'));
export const EditPriceRule = lazy(() => import('../components/EditPriceRule'));

export const Welcome = lazy(() => import('../components/welcome/Welcome'));
