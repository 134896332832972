import { QueryClient } from '@tanstack/react-query';

/**
 * Creates a new QueryClient with the default configuration.
 * @returns The default query client.
 */
export const createQueryClient = (): QueryClient => {
	return new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnMount: false,
				refetchOnWindowFocus: false,
				refetchOnReconnect: false,
				retry: false,
				staleTime: Infinity
			}
		}
	});
};
